import * as React from "react";

const VoiceIcon = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 261 231"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="currentColor" fillRule="nonzero">
          <path
            d="M184.11,192.88 C184.05,187.02 183.98,181.17 183.92,175.31 C183.92,175.22 183.92,175.13 183.92,175.04 C183.92,174.94 184,174.98 184.07,174.98 C184.2,174.98 184.32,174.98 184.45,174.97 C184.7,174.96 184.96,174.96 185.21,174.95 C185.72,174.93 186.23,174.91 186.75,174.88 C187.79,174.82 188.83,174.73 189.86,174.61 C191.99,174.37 194.1,174.02 196.19,173.55 C205.17,171.53 213.54,167.45 220.64,161.61 C235.85,149.12 243.78,129.99 241.87,110.41 C239.96,90.83 228.43,73.52 211.09,64.23 C203.18,59.99 194.42,57.62 185.45,57.29 C184.93,57.27 184.41,57.26 183.89,57.25 C183.63,57.25 183.38,57.25 183.12,57.25 C182.99,57.25 182.86,57.25 182.74,57.25 C182.59,57.25 182.65,57.21 182.65,57.1 C182.65,57.01 182.65,56.91 182.65,56.82 C182.63,55.34 182.62,53.86 182.6,52.37 C182.57,49.41 182.54,46.44 182.5,43.48 C182.49,42.74 182.48,42 182.48,41.26 C182.48,40.89 182.47,40.52 182.47,40.15 C182.47,39.96 182.47,39.78 182.46,39.59 C182.46,39.54 182.46,39.5 182.46,39.45 C182.46,39.43 182.46,39.4 182.46,39.38 C182.46,39.36 182.44,39.33 182.48,39.33 C182.55,39.33 182.62,39.33 182.68,39.33 C183.77,39.32 184.86,39.34 185.96,39.37 C188.2,39.45 190.43,39.62 192.66,39.9 C232.77,44.83 262.19,80.01 259.95,120.36 C257.7,160.74 224.54,192.45 184.11,192.88 Z"
            id="Path"
          ></path>
          <path
            d="M183.73,157.07 C183.69,153.8 183.66,150.53 183.62,147.26 C183.6,145.62 183.58,143.99 183.57,142.35 C183.56,141.53 183.55,140.71 183.54,139.9 C183.54,139.7 183.54,139.49 183.53,139.29 C183.53,139.27 183.5,139.17 183.54,139.17 C183.57,139.17 183.59,139.17 183.62,139.17 C183.67,139.17 183.72,139.17 183.78,139.17 C183.99,139.17 184.2,139.16 184.41,139.15 C188.04,138.97 191.57,137.94 194.72,136.14 C203.62,131.06 208.07,120.79 205.71,110.82 C203.35,100.86 194.72,93.65 184.5,93.11 C184.11,93.09 183.72,93.08 183.33,93.08 C183.23,93.08 183.14,93.08 183.04,93.08 C182.99,93.08 183.03,92.98 183.03,92.96 C183.03,92.91 183.03,92.87 183.03,92.82 C183.03,92.73 183.03,92.63 183.03,92.54 C183.03,92.16 183.02,91.78 183.02,91.4 C183,89.89 182.99,88.37 182.97,86.86 C182.94,83.83 182.9,80.8 182.87,77.77 C182.86,77.01 182.85,76.26 182.85,75.5 C182.85,75.41 182.85,75.31 182.85,75.22 C182.85,75.15 182.97,75.19 183.01,75.19 C183.13,75.19 183.25,75.19 183.37,75.19 C183.61,75.19 183.85,75.19 184.09,75.2 C185.06,75.22 186.04,75.27 187.01,75.36 C189.03,75.54 191.01,75.87 192.99,76.35 C213.05,81.23 226.34,100.29 224,120.8 C221.63,141.29 204.38,156.84 183.73,157.07 Z"
            id="Path"
          ></path>
          <path
            d="M60.09,166.4 L0.18,166.4 L0.18,65.82 L59.48,65.82 L136.87,0.83 L162.66,0.83 L162.66,115.85 L162.66,230.87 L136.87,230.87 L60.09,166.4 Z M66,83.73 L66.61,148.49 L143.39,212.96 L144.75,212.96 L144.75,18.74 L143.39,18.74 L66,83.73 Z M48.7,83.73 L18.09,83.73 L18.09,148.49 L48.7,148.49 L48.7,83.73 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default VoiceIcon;
