export const getDevice = () => {
    if (typeof window === 'undefined') {
      return 'desktop'
    }
    if (document.body.clientWidth < 550) {
      return 'mobile'
    }
    if (document.body.clientWidth < 770) {
      return 'tablet'
    }
    if (document.body.clientWidth < 990) {
      return 'smallDesktop'
    }
    return 'desktop'
  }

export const isBrowser = () => typeof window !== 'undefined'
