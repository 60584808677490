import * as React from "react";

const phrases = [
    //0
    {
        pl: 'Na terenie Kościuszkowskiej Dzielnicy Mieszkaniowej znajdziesz pomarańczowe tabliczki z zagadkami do wyjaśnienia. Po rozwikłaniu tajemnic uzupełnij formularz i wykonaj zadanie finałowe. Zanim rozpoczniesz zapoznaj się z zasadami gry.',
        en: 'In the Kościuszko Housing District, you can find orange plates with riddles to solve. After unraveling the secrets, complete the form and the final task. Before you start, please read the rules of the game.'
    },
    //1
    {
        pl: <span>GlassGo<sup>2</sup> to gra miejska rozgrywająca się we Wrocławiu. Podczas trwającej całe wakacje gry zapraszamy do wyjścia poza ramę galerii sztuki oraz do wspólnego mapowania miejskiej przestrzeni. Podobno domeną Wrocławia jest właśnie ceramika i szkło - sprawdźmy to w GlassGo<sup>2</sup> - drugiej edycji projektu. </span>,
        en: <span>GlassGo<sup>2</sup> is a city game set in Wrocław. During the game, we invite you to go beyond the framework of the art gallery and to map the city space together. Apparently, the domain of Wrocław is ceramics and glass - let's check it out in GlassGo<sup>2</sup> - the second edition of the project.</span>
    },
    //2
    {
        pl: 'To druga edycja badawczego projektu galerii SIC! BWA Wrocław skupiającego się na analizie kondycji i funkcji szkła oraz ceramiki w przestrzeni miejskiej.',
        en: 'This is the second edition of the research project created by the SIC! BWA Wrocław gallery which focuses on the analysis of the condition and function of glass and ceramics in urban space.'
    },
    //3
    {
        pl: 'Rzadko rozpatrywana jako odpowiednia do ekspozycji powyższych mediów, odkrywa przed nami nowe konteksty oraz pozwala na ich alternatywne "czytanie" - jako obiektów dekoracyjnych, funkcjonalnych, ale także tych będących częścią codziennej naszej rzeczywistości. Rozkładając na części te elementy, biorąc pod lupę oczywiścości lub skupiając wzrok na tym, co niezauważalne, odkryjemy podwójne dna i ich nowe aspekty funkcjonowania.',
        en: 'Rarely considered as appropriate for the exhibition of the above-mentioned media, it reveals new contexts and allows for their alternative "reading" - as decorative and functional objects, but also those that are part of our everyday reality. Disassembling these elements, taking the obvious things under a magnifying glass or focusing our eyes on what is unnoticeable, we will discover their second levels and new aspects of functioning.',
    },
    //4
    {
        pl: 'We współpracy z grupą specjalistów związanych z takimi dyscyplinami jak projektowanie szkła, historia sztuki czy problematyka przestrzeni miejskiej eksplorujemy, analizujemy i definiujemy nowe tropy. Patrząc na media takie jak szkło i ceramika zachęcamy do uważnego przyjrzenia się różnym kontekstom ich obecności i funkcjonowania w sferze publicznej.',
        en: 'In cooperation with a group of specialists related to such disciplines as glass design, art history or the issues of urban space, we invite you to explore, analyze and define new clues. Looking at media such as glass and ceramics, we encourage you to carefully look at the various contexts of their presence and functioning in the public sphere.'
    },
    //5
    {
        pl: 'Ważne daty:',
        en: 'Important dates:'
    },
    //6
    {
        pl: 'Czas trwania',
        en: 'Duration',
    },
    //7 
    {
        pl: '31 lipca - 3 października 2021',
        en: 'July 31 - October 3, 2021',
    },
    //8
    {
        pl: 'Rozstrzygnięcie i przyznanie nagród',
        en: 'Settlement of the game and awarding of prizes',
    },
    //9
    {
        pl: '4-17 października 2021',
        en: 'October 4-17, 2021',
    },
    //10
    {
        pl: 'Rozgrywki specjalne:',
        en: 'Special Gameplays:'
    },
    //11
    {
        pl: '31 lipca | 13.00',
        en: 'July 31 | 13.00',
    },
    //12
    {
        pl: 'rozgrywka dla wszystkich. Prowadzenie Barbara Celebucka',
        en: 'a gameplay for everyone. Conducted by Barbara Celebucka'
    },
    //13
    {
        pl: '7 sierpnia | 13.00',
        en: 'August 7 | 13.00',
    },
    //14
    {
        pl: 'Rozgrywka dla dzieci w wieku 5-8 lat. Prowadzenie Alicja Abramowicz',
        en: 'A gameplay for children aged 5-8. Conducted by Alicja Abramowicz'
    },
    //15
    {
        pl: '21 sierpnia | 13.00',
        en: 'August 21 | 13.00',
    },
    //16
    {
        pl: 'Rozgrywka dla Głuchych. Prowadzenie Ewa Pluta',
        en: 'A gameplay for deaf and hard of hearing. Conducted by Ewa Pluta'
    },
    // 17
    {
        pl: 'Autorzy',
        en: 'Authors'
    },
    //18
    {
        pl: 'Kuratorki',
        en: 'Curators'
    },
    //19
    {
        pl: 'Mika Drozdowska, Iwona Kałuża',
        en: 'Mika Drozdowska, Iwona Kałuża'
    },
    //20
    {
        pl: 'Ekspertki',
        en: 'Experts'
    },
    //21
    {
        pl: 'Barbara Celebucka, Mika Drozdowska, Agata Grzych, Iwona Kałuża, Karina Marusińska, Alicja Patanowska',
        en: 'Barbara Celebucka, Mika Drozdowska, Agata Grzych, Iwona Kałuża, Karina Marusińska, Alicja Patanowska'
    },
    //22
    {
        pl: 'Przewodniczki',
        en: 'Guides'
    },
    //23
    {
        pl: 'Alicja Abramowicz, Barbara Celebucka, Ewa Pluta',
        en: 'Alicja Abramowicz, Barbara Celebucka, Ewa Pluta'
    },
    //24
    {
        pl: 'Projekt graficzny',
        en: 'Graphic design'
    },
    //25
    {
        pl: 'Hubert Kielan',
        en: 'Hubert Kielan'
    },
    //26
    {
        pl: 'Programowanie',
        en: 'Programming'
    },
    //27
    {
        pl: 'Konrad Kruk',
        en: 'Konrad Kruk'
    },
    //28
    {
        pl: 'Promocja',
        en: 'Promotion'
    },
    //29
    {
        pl: 'Klementyna Sęga, Żaneta Wańczyk',
        en: 'Klementyna Sęga, Żaneta Wańczyk'
    },
    //30
    {
        pl: 'Barbara Celebucka',
        en: 'Barbara Celebucka'
    },
    //31
    {
        pl: 'socjolożka i animatorka kultury',
        en: 'sociologist ang cultural animation'
    },
    //32
    {
        pl: 'Mika Drozdowska',
        en: 'Mika Drozdowska'
    },
    //33
    {
        pl: 'kuratorka galerii SIC! BWA Wrocław',
        en: 'curator of SIC! BWA Wrocław gallery'
    },
    //34
    {
        pl: 'Agata Grzych',
        en: 'Agata Grzych'
    },
    //35
    {
        pl: 'artystka',
        en: 'artist'
    },
    //36
    {
        pl: 'Iwona Kałuża',
        en: 'Iwona Kałuża'
    },
    //37
    {
        pl: 'historyczka sztuki',
        en: 'art historian'
    },
    //38
    {
        pl: 'Karina Marusińska',
        en: 'Karina Marusińska'
    },
    //39
    {
        pl: 'artystka',
        en: 'artist'
    },
    //40
    {
        pl: 'Alicja Patanowska',
        en: 'Alicja Patanowska'
    },
    //41
    {
        pl: 'artystka',
        en: 'artist'
    },
    //42
    {
        pl: 'Mapa',
        en: 'Map'
    },
    //43
    {
        pl: 'Opis',
        en: 'Description'
    },
    //44
    {
        pl: 'Autorzy',
        en: 'Authors'
    },
    //45
    {
        pl: 'Lektor pl',
        en: 'Lector pl'
    },
    //46
    {
        pl: 'Lektorka eng',
        en: 'Lector eng'
    },
    //47
    {
        pl: 'Janek Pelczar',
        en: 'Janek Pelczar'
    },
    //48
    {
        pl: 'Zofia Reznik',
        en: 'Zofia Reznik'
    },
    //49
    {
        pl: 'Otwórz mapę',
        en: 'Open a map'
    },
    //50
    {
        pl: 'Zagadka',
        en: 'Riddle'
    }
]

export default phrases
