import * as React from "react";

const ArrowRight = (props) => {
  return (
    <svg
      viewBox="0 0 254 216"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(127.000000, 108.000000) scale(-1, 1) translate(-127.000000, -108.000000) "
          fill="currentColor"
          fillRule="nonzero"
        >
          <polygon points="253.89 98.91 35.12 98.91 120.82 13.22 107.93 0.33 13.12 95.14 0.65 107.61 0.23 108.03 107.51 215.31 120.41 202.42 35.12 117.14 253.88 117.14"></polygon>
        </g>
      </g>
    </svg>
  );
};

export default ArrowRight;
