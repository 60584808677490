import * as React from "react";
import { css } from "@emotion/react";

const tableListStyle = (theme) => css`
  h2 {
    ${theme.typography.basic};
    color: inherit;
    border-bottom: 1px solid currentColor;
    margin: 0;
    padding-bottom: 5px;
    padding-left: 35px;
  }
  ol {
    margin: 0;
    padding: 0;
    counter-reset: list;

    li {
      border-bottom: 1px solid currentColor;
      padding: 15px 0;
      display: grid;
      grid-template-columns: 35px 40% 1fr;

      &:before {
        counter-increment: list;
        content: "0" counter(list);
        display: inline-block;
        right: 0%;
        font-size: 0.7rem;
      }

      span:last-child {
        text-align: left;
      }
    }
  }
`;

const TableList = ({ children }) => {
    return (
        <div css={tableListStyle}>
            {children}
        </div>
    )
}

export default TableList
