import * as React from "react";
import VoiceIcon from "./VoiceIcon";
import { css } from "@emotion/react";
import useSound from "use-sound";
import { getDevice } from "./utils";

const buttonStyle = css`
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: transparent;
  color: inherit;
  font-size: 0.8rem;
  font-family: inherit;
  text-align: left;
`;

const buttonLabelStyle = css`
  max-width: 110px;
  margin-left: 30px;
`;

const getPhrase = (language, playing) => {
  if (language === "pl") {
    if (playing) {
      return "Zatrzymaj audiodeskrypcję";
    }
    return "Uruchom audiodeskrypcję";
  }
  if (playing) {
    return "Stop audio description ";
  }
  return "Play audio description";
};

const getIconSize = (device) => {
    if (device === "mobile") {
      return "30px";
    }
    if (device === "tablet") {
      return "50px";
    }
    return "60px";
  };

const Player = ({ language, file }) => {
  const [play, { pause }] = useSound(file);
  const [playing, setPlaying] = React.useState(false);
  React.useEffect(() => {
    return () => pause()
  }, [language, file, pause])
  const iconSize = getIconSize(getDevice());
  return (
    <button
      css={buttonStyle}
      onClick={() => {
        playing ? pause() : play();
        setPlaying((value) => !value);
      }}
    >
      <VoiceIcon height={iconSize} />
      <div css={buttonLabelStyle}>{getPhrase(language, playing)}</div>
    </button>
  );
};

export default Player;
